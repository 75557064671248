.label-attachment{
    font-size: small;
    text-align: center;
}
.image-attachment{
    max-height: 300px;
    max-width: 300px;
    cursor: pointer;
}
.image-attachment-large-container{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.image-attachment-large{
    max-height: inherit;
    max-width: 1100px;
    cursor: pointer;
}

.custom-file-button::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-button::before {
    content: 'ADJUNTAR';
    background: #5D73E2;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 0.875rem 1rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    }
.custom-file-button:hover::before {
    background: #3d55df;
}
.custom-file-button:active::before {
    background: #3d55df;
}

#chat{
    padding-left:0;
    margin:0;
    list-style-type:none;
    overflow-y:scroll;
    height:535px;
    border-top:2px solid #fff;
    border-bottom:2px solid #fff;
}
#chat li{
    padding:10px 30px;
}
#chat h2,#chat h3{
    display:inline-block;
    font-size:13px;
    font-weight:normal;
    margin-right: 5px;
}
#chat h3{
    color:#bbb;
}
#chat .entete{
    margin-bottom:5px;
}
#chat .message{
    padding:20px;
    color:#fff;
    line-height:25px;
    max-width:90%;
    display:inline-block;
    text-align:left;
    border-radius:5px;
}
#chat .me{
    text-align:right;
}
#chat .you .message{
    background-color:#3D55DF;
}
#chat .me .message{
    background-color:#B9BAC0;
}
#chat .triangle{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
}
#chat .you .triangle{
    border-color: transparent transparent #3D55DF transparent;
    margin-left:15px;
}
#chat .me .triangle{
    border-color: transparent transparent #B9BAC0 transparent;
    margin-left: auto;
    margin-right:15px;
}

.insideBox {
    display: block;
}

.spanBox {
    border-radius: 10px;
    display: inline-block;
    padding: 5px;
    margin: 10px;
    height: 130px;
    width: 130px;
    background: rgba(255, 255, 255, 0.3);
    color: #FFFFFF;
    font-size: 12px
}

.topup-button {
    margin-bottom: 10px;
}

.topup-values {
    padding: 10px;
}

.topup-col-centered {
    float: none;
    margin: 0 auto;
}

p.item-selected {
    color: #F68240;
    font-weight: bold;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

span.item-selected {
    color: #F68240;
    font-weight: bold;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

img.item-selected {
    box-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px darkblue;
    cursor: pointer;
}

img.product-image:hover {
    box-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px darkblue;
    cursor: pointer;
}

.carrier-logo {
    display: inline;
    padding: 10px;
    max-width: 100px;
    cursor: pointer;
}

.carrier-logo.selected {
    max-width: 130px;
}

.product-logo {
    margin: 0;
    padding: 0;
    max-width: 120px;
}

.search-box {
    background-color: #484B71;
    border-radius: 0.75rem;
    font-size: 0.85rem;
    padding: 10px;
    margin-bottom: 15px
}


.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 100%;
    }
    .container-fluid{
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container, .container-sm, .container-md {
        max-width: 98%;
    }
    .container-fluid{
        max-width: 98%;
    }
}

@media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
        max-width: 95%;
    }
    .container-fluid{
        max-width: 95%;
    }
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 90%;
    }
    .container-fluid{
        max-width: 90%;
    }
}