.card {
    color: #3d4042;
}

.plataforma-bg {
    padding-top: 100px;
    color: #FFFFFF !important;
    background: url("../assets/img/plataformas3.webp") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.align-center {
    width: 100%;
    text-align: center;
    align-content: center;
}